<template>
  <div>
    <eden-page-header :title="'Orders'" />
    <p v-if="loading">Loading</p>
    <el-tabs v-else v-model="tab" @tab-click="updateRoute">
      <el-tab-pane
        v-for="(tab, i) in tabs"
        :key="i"
        :label="tab.label"
        :name="tab.name"
      >
        <component :is="`provisioned-orders-${tab.name}`" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import * as actions from "@/store/action-types";

export default {
  name: "ProvisionedOrders",
  components: {
    ProvisionedOrdersBeauty: () =>
      import(
        "@/components/ServiceOperations/ProvisionedOrders/Beauty/ProvisionedOrdersBeauty"
      ),
    ProvisionedOrdersCleaning: () =>
      import(
        "@/components/ServiceOperations/ProvisionedOrders/Cleaning/ProvisionedOrdersCleaning"
      ),
    ProvisionedOrdersLaundry: () =>
      import(
        "@/components/ServiceOperations/ProvisionedOrders/Laundry/ProvisionedOrdersLaundry"
      ),
    ProvisionedOrdersMeal: () =>
      import(
        "@/components/ServiceOperations/ProvisionedOrders/Food/ProvisionedOrdersMeal"
      ),
  },
  data() {
    return {
      loading: false,
      tab: "cleaning",
    };
  },
  computed: {
    location() {
      return this.$store.getters.location;
    },
    services() {
      return this.$store.getters.services;
    },
    tabs() {
      return this.services.map((service) => {
        return {
          label: service.slug === "meal" ? "Food" : service.service_name,
          name: service.slug,
        };
      });
    },
  },
  created() {
    this.loading = true;

    let { t } = this.$route.query;
    this.tab = t ? t : "meal";

    this.$store.dispatch(actions.GET_SERVICE_PARTNERS_LIST).then(() => {
      this.loading = false;
    });
  },
  methods: {
    updateRoute(tab) {
      const currentTab = this.$route.query.t;

      if (currentTab === this.tab) {
        return false;
      }
      this.$router.push({
        name: "serviceoperations.serviceoperations-orders.index",
        query: { t: tab.name },
      });
    },
  },
};
</script>
